/* eslint-disable react/no-unknown-property */
// TODO: REFACTORING
import React from 'react';
import { Helmet } from 'react-helmet';
import { SITE_DOMAIN } from '@gatsby-local-plugin/gatsby-local-env-variables';

import { useCurrentLang } from '../utils/translate';
import parse from 'html-react-parser';

const HREFLANG_MAP = {
  en_US: 'en',
  pt_BR: 'pt-br',
  es_ES: 'es',
  // it_IT: 'it',
};

/**
 * @param {*} pageLang - Current
 * @param {*} translations - It will generate the hreflang tags
 * @param boolean noIndex - No index for robots
 */
const SEO = ({
  yoast_head,
  CustomHeads,
  pageLang,
  translations = [],
  noIndex = false,
}) => {
  //
  // Fixing SEO with ssr during the build.
  // The html generated use the inital value of context (pt_BR) instead the language of page.
  //
  const lang = (pageLang || useCurrentLang()).replace('_', '-');
  const defaultPage = translations.find(
    term => term.polylang_current_lang === 'en_US'
  );

  const canonicalPage = translations.find(
    term => term.polylang_current_lang === lang.replace('-', '_')
  );

  return (
    <Helmet>
      <html lang={lang} />
      {parse(yoast_head)}
      {translations.map((translation, index) => {
        return (
          <link
            key={index}
            rel={'alternate'}
            href={SITE_DOMAIN + translation.path}
            hreflang={HREFLANG_MAP[translation.polylang_current_lang]}
          />
        );
      })}

      {!!defaultPage && (
        <link
          rel="alternate"
          href={`${SITE_DOMAIN}${defaultPage.path}`}
          hreflang="x-default"
        />
      )}

      {!!canonicalPage && (
        <link rel="canonical" href={`${SITE_DOMAIN}${canonicalPage.path}`} />
      )}
    </Helmet>
  );
};

export default SEO;
